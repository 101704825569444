@keyframes appear {
    from{
        opacity: 0;
        scale: 0.5;
    }
    to{
        opacity: 1;
        scale: 1;
    }
}
.desc-card{
    
    animation: appear linear;
    animation-timeline: view();
    animation-range: entry 0 cover 40%;
}